import React from "react"
import Four04 from '../assets/images/404.svg'
import CatoImage from '../components/cato-image';
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../assets/styles/pages/404.scss';

const NotFoundPage = () => (
  <Layout className="not-found-page" lang={'EN'} transparentMenu={true}>
    <SEO title="404: Not found" />
      <section className="not-found-page-wrapper">
        <h1>Page not found</h1>
        <p>You just hit a route that doesn&#39;t exist... <br/>the sadness.</p>
          <div className="img-wrapper">
              <CatoImage img={Four04}/>
          </div>
      </section>
  </Layout>
)

export default NotFoundPage
